import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import ReactPlayer from "react-player/lazy"
import fetch from "node-fetch"
import { useForm } from "react-hook-form"
import { FaSpinner } from "react-icons/fa"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"
import { useQuery } from "react-query"
import { MdClose, MdKeyboardArrowRight } from "react-icons/md"

import Layout from "../components/Layout"
import TextRender from "../components/TextRender"
import Curve from "../components/Curve"
import { linkResolver } from "../../prismic.config"
import kebabCase from "lodash/kebabCase"
import format from "date-fns/format"
import Modal from "../components/Modal"

export default function Helpdesk({ data, pageContext }) {
  useEffect(() => {
    fetch("https://functions.greatcontent.com/.netlify/functions/search")
  }, [])
  const [term, setTerm] = useState("")
  const search = useForm()
  const query = useQuery(
    ["search", term],
    async () => {
      return fetch(
        "https://functions.greatcontent.com/.netlify/functions/search",
        {
          method: "POST",
          body: JSON.stringify({ term, category: pageContext.category }),
        }
      ).then((res) => res.json())
    },
    {
      enabled: Boolean(term.trim().length),
    }
  )
  useEffect(() => {
    let script1, script2, script3, script4
    if (typeof window !== "undefined") {
      script1 = document.createElement("script")
      script1.id = "script1"
      script1.innerText =
        'window.fwSettings={	"widget_id":27000000126	};	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() '
      document.body.appendChild(script1)
    }
    if (typeof window !== "undefined") {
      let script3 = document.createElement("script")
      script3.id = "script4"
      script3.src = "https://widget.freshworks.com/widgets/27000000126.js"
      script3.async = true
      script3.defer = true

      document.body.appendChild(script3)
    }
    if (typeof window !== "undefined" && pageContext.category == "Linguists") {
      let script2 = document.createElement("script")
      script2.id = "script2"
      script2.innerText =
        'FreshworksWidget("prefill", "ticketForm", {  description:    "Incase you are an existing client please enter your user ID or business email above.",  type: "Linguist",   custom_fields: {     cf_your_gc_unique_id_eg_u58mlh6: "unknown",   }, }); FreshworksWidget("hide", "ticketForm", [   "type",   "custom_fields.cf_your_gc_unique_id_eg_u58mlh6", ])'
      document.body.appendChild(script2)
    }
    if (typeof window !== "undefined" && pageContext.category == "Clients") {
      script4 = document.createElement("script")
      script4.id = "script4"
      script4.innerText =
        'FreshworksWidget("prefill", "ticketForm", {  description:     "Incase you are an existing client please enter your user ID or business email above.",   type: "Client",custom_fields: {     cf_your_gc_unique_id_eg_u58mlh6: "unknown",   }, }); FreshworksWidget ("hide", "ticketForm", [   "type",    "custom_fields.cf_your_gc_unique_id_eg_u58mlh6",])'
      document.body.appendChild(script4)
    }
    return () => {
      if (typeof window !== "undefined") {
        const el1 = document.getElementById("script1")
        const el2 = document.getElementById("script2")
        const el3 = document.getElementById("script3")
        const el4 = document.getElementById("script4")
        const el5 = document.getElementById("freshworks-frame")
        const el6 = document.getElementById("freshworks-container")
        el1 && el1.remove()
        el2 && el2.remove()
        el3 && el3.remove()
        el4 && el4.remove()
        el5 && el5.remove()
        el6 && el6.remove()
      }
    }
  }, [])

  const seoData = {
    meta_title: data.helpdesk.data.title.text,
  }
  return (
    <Layout transparentNavigation pageContext={pageContext} seo={seoData}>
      <div className="pt-40 pb-20 phone:pt-32 bg-light ">
        <div
          className="mx-auto prose text-center"
          css={css`
            h1 {
              ${tw`text-2xl`}
            }
          `}
        >
          <TextRender text={get(data, "helpdesk.data.title.raw")} />
        </div>
        <div className="container lg:max-w-6xl">
          <SearchForm
            data={data}
            setTerm={setTerm}
            search={search}
            query={query}
          ></SearchForm>
        </div>
      </div>
      <Curve className="text-white" />
      <div className="container lg:max-w-6xl relative z-10 -mt-5 text-center">
        {query.isSuccess && (
          <div className="inline-flex flex-wrap items-center justify-center px-6 py-2 text-sm font-semibold bg-white rounded text-light-text phone:px-3 filter-shadow">
            <Link>FAQ</Link> <MdKeyboardArrowRight />{" "}
            <span className="text-primary">
              {get(data, "helpdesk.data.search_results")}
            </span>
          </div>
        )}
      </div>
      {query.isSuccess && (
        <SearchResults
          helpdesk={get(data, "helpdesk")}
          term={term}
          data={query.data}
          pageContext={pageContext}
        />
      )}
      {!query.isSuccess && (
        <div className="container lg:max-w-6xl py-20">
          <div className="prose mx-auto max-w-lg text-center  mt-10">
            <TextRender text={get(data, "helpdesk.data.faq_text.raw")} />
          </div>
          <div className="my-16 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 md:gap-8  lg:gap-10">
            {data.folders.nodes.map((folder) => {
              const article = data.articles.group.find(
                (a) => a.fieldValue == folder.name
              )
              return (
                <div key={folder.name}>
                  <Link
                    to={linkResolver({
                      type: "page",
                      lang: pageContext.lang,
                      uid: `helpdesk/${kebabCase(pageContext.category)}/${get(
                        article,
                        "nodes[0].slug"
                      )}`,
                    })}
                    className="block items-stretch   w-full border border-transparent hover:border-primary    hover:bg-white bg-light group filter-shadow rounded px-4 py-3 text-sm"
                  >
                    <h4 className="font-semibold mb-1">{folder.name}</h4>
                    {folder.description && (
                      <p className="text-light-text">{folder.description}</p>
                    )}
                  </Link>
                </div>
              )
            })}
          </div>

          <div className="prose mx-auto max-w-lg text-center mt-20 relative">
            <div
              className="pointer-events-none absolute -top-12"
              id="tutorial"
            ></div>
            <TextRender text={get(data, "helpdesk.data.tutorials_text.raw")} />
          </div>
          <div className="my-16 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 md:gap-8  lg:gap-10">
            {get(data, "helpdesk.data.tutorials", []).map((tutorial, index) => {
              return (
                <Video
                  key={index}
                  preview={get(tutorial, "preview.fluid")}
                  video_link={tutorial.video_link}
                  title={tutorial.title.raw}
                ></Video>
              )
            })}
          </div>
        </div>
      )}
    </Layout>
  )
}

function Video({ video_link, preview, title }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div>
        <div
          className="flex items-center justify-center    w-full    overflow-hidden     group  filter-shadow rounded px-4   relative text-sm cursor-pointer"
          style={{
            padding: "56.25% 0 0 0",
          }}
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <div
            tw="absolute inset-0 pointer-events-none bg-light"
            onClick={(e) => {
              e.preventDefault()
            }}
            css={css`
              > div {
                width: 100% !important;
                height: 100% !important;
              }
            `}
          >
            <GatsbyImage fluid={preview} />
          </div>

          <PlayButton className="z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        </div>
        <div className="mt-3">
          {" "}
          <TextRender text={title} />
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false)
        }}
      >
        <div
          className="relative w-full bg-gray-900"
          css={css`
            padding-bottom: calc(9 / 16 * 100%);

            .react-player {
              transition: transform 300ms;
              width: 100% !important;
              height: 100% !important;
              transform-origin: "center center";
            }
          `}
        >
          <MdClose
            className="text-white w-10 h-10 absolute -top-10 -right-10 phone:right-0 cursor-pointer"
            onClick={() => {
              setIsOpen(false)
            }}
          />
          <ReactPlayer
            className=" absolute react-player"
            url={video_link}
            autoplay={true}
            config={{
              vimeo: {
                playerOptions: {
                  autoplay: true,
                  responsive: true,
                },
              },
            }}
            controls
            responsive
          />
        </div>
      </Modal>
    </>
  )
}

export const query = graphql`
  query($id: String, $category: String) {
    folders: allFolder(filter: { category: { name: { eq: $category } } }) {
      nodes {
        name
        description
      }
    }
    articles: allArticle {
      group(limit: 1, field: folder___name) {
        fieldValue
        nodes {
          slug
        }
      }
    }
    helpdesk: prismicHelpdesk(id: { eq: $id }) {
      data {
        faq_text {
          raw
        }
        category
        modified
        search_button
        search_results
        search_results_text
        search_text
        title {
          raw
          text
        }

        tutorials {
          preview {
            alt
            url
            dimensions {
              width
              height
            }
            fluid(maxWidth: 300, imgixParams: { maxW: 300 }) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }

          video_link
          title: title1 {
            raw
          }
        }
        tutorials_text {
          raw
        }
      }
    }
  }
`
export function SearchForm(props) {
  return (
    <form
      css={css`
        border: 0.5px solid #8492a6;
      `}
      tw="focus-within:ring-1 focus-within:ring-deep-blue"
      className="max-w-lg bg-white  rounded  mx-auto mt-8 py-2 pl-2 flex justify-between items-center"
      onSubmit={props.search.handleSubmit((values) => {
        props.setTerm(values.term)
      })}
    >
      <input
        name={"term"}
        className="  placeholder-light-text px-2 flex-grow focus:outline-none"
        ref={props.search.register()}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
        placeholder={get(props.data, "helpdesk.data.search_text")}
      />
      {props.query.isSuccess && (
        <span
          className="px-2 mx-1 cursor-pointer inline-block"
          onClick={() => {
            props.setTerm("")
            props.search.reset({
              term: "",
            })
          }}
        >
          <MdClose />
        </span>
      )}
      <button
        className="primary-button mr-2 py-1.5 flex items-center focus:outline-none"
        style={{
          minWidth: 0,
        }}
      >
        {get(props.data, "helpdesk.data.search_button")}
        {props.query.isFetching && <FaSpinner className="animate-spin ml-2" />}
      </button>
    </form>
  )
}
export function SearchResults({ term, data, pageContext, helpdesk }) {
  return (
    <div className="container lg:max-w-6xl py-20">
      <div className="text-2xl mb-8">
        {get(helpdesk, "data.search_results_text")} "{term}"
      </div>
      <div className="space-y-6">
        {data.map((a) => {
          return (
            <Link
              to={linkResolver({
                lang: pageContext.lang,
                type: "page",
                uid: `helpdesk/${kebabCase(a.category_name)}/${kebabCase(
                  a.title
                )}`,
              })}
              className=" card p-4 rounded border-light-2  border hover:border-primary flex phone:flex-wrap justify-between"
            >
              <div>
                {" "}
                <h4 className="text-base text-primary">{a.title}</h4>
                <p className="text-xs mb-3 flex items-center space-x-1 text-light-text">
                  {a.category_name} <MdKeyboardArrowRight />
                  {a.folder_name}
                </p>
                <p className="text-sm text-light-text">
                  {get(a, "description_text", "").slice(0, 200)}
                  {get(a, "description_text", "").length > 200 && "..."}
                </p>
              </div>
              <div className="w-full md:w-48 flex-shrink-0 phone:flex phone:mt-6 items-center   ">
                <div className="text-xs text-light-text md:text-right phone:mr-4 phone:order-first">
                  {get(helpdesk, "data.modified")}{" "}
                  {a.updated_at &&
                    format(new Date(a.updated_at), "E, d MMM, y ")}
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

function PlayButton(props) {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      {...props}
      css={css`
        .group:hover & {
          ${tw`filter-shadow-light`}
          path {
            fill: #19bc15;
          }
        }
      `}
    >
      <circle cx="28.5" cy="28.5" r="28.5" fill="white" />
      <path
        d="M23.7611 21.4368L36.7207 28.919L23.7611 36.4012L23.7611 21.4368Z"
        stroke="#19BC15"
      />
    </svg>
  )
}
